
import { defineComponent, onMounted, reactive,ref } from "vue";
import mAxiosApi from "@/api";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";

export default defineComponent({
  name: "account-settings",
  components: {},
  setup() {
    const store = useStore();
    const state = reactive({
      loaderEnabled: false,
      loaderEnabledTest: false,
      loaderEnabledPass: false,
      form: {
        us_default_networks:[]
      } as any,
      password: {
        old_repassword: "",
        new_password: "",
        new_repassword: "",
      },
      organisations: null as Array<Record<"label" | "value", string>> | null,
      products: [] as any, 
    });  

    onMounted(async () => {
      state.loaderEnabled = true
      if(!store.getters.getOrg.idOrg) return
      const [me,orga,products] = await Promise.all([getAxios("/me"), getAxios("/orga"),  getAxios("/productsPerOrga")])
      state.form = {...me, us_default_networks:JSON.parse(me.us_default_networks)?.value ?? []}
      state.organisations = orga
      state.products = products
      state.loaderEnabled = false
    });

    const validateForm = async () => {
      const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!regexMail.test(state.form.us_username)) {
        ElNotification({
          title: "Error",
          message: "Le mail de connexion doit être un mail valide",
          type: "error",
        });
        return false;
      }

      if (
        !state.form.us_username ||
        !state.form.us_prenom ||
        !state.form.us_nom ||
        !state.form.us_api_key_meraki
      ) {
        ElNotification({
          title: "Error",
          message: "Un des champs obligatoire n'est pas saisi",
          type: "error",
        });
        return false;
      }

      state.loaderEnabled = true;
      let form = {...state.form};
      form.us_default_networks = {value:form.us_default_networks}
      form = JSON.stringify(form)
  
      await mAxiosApi.post("/validateFormProfil", form, {
        headers: { "Content-Type": "application/json" },
      });
      ElNotification({
        title: "Succés",
        message: "Changements effectués",
        type: "success",
      });

      state.loaderEnabled = false;
    };

    const changePassword = async () => {
      state.loaderEnabledPass = true;

      const form = JSON.stringify(state.password);
      const response = await mAxiosApi.post("/changePassword", form, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.errors) {
        ElNotification({
          title: "Erreur",
          message: response.data.errors,
          type: "error",
        });
      } else {
        ElNotification({
          title: "Succés",
          message: "Changements effectués",
          type: "success",
        });
      }

      state.loaderEnabledPass = false;
    };

    const test_api = async () => {
      state.loaderEnabledTest = true;
      const mOrga = await getAxios(
        "/testapi?key=" + state.form.us_api_key_meraki
      );
      state.loaderEnabledTest = false;
      if (mOrga.errors) {
        ElNotification({
          title: "Error",
          message:
            "Il semble y avoir une erreur avec la clé API saisie, veuillez la vérifier, svp",
          type: "error",
        });
      } else {
        ElNotification({
          title: "Succés",
          message: "La clé saisie semble fonctionnelle !",
          type: "success",
        });
      }
    };

    return {
      state,
      validateForm,
      changePassword,
      test_api,
      store,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
